@import "../../styles/base/base.scss";

.messages-container-normal-size {
  height: calc(100dvh - 120px);
}
.messages-container-buttons-size {
  height: calc(100dvh - 168px);
}

.messages-container {
  // position: fixed;
  // top: 55px;
  // bottom: 62px;
  width: 100%;
  max-width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  // height: calc(100dvh - 120px);

  &.open-iframe {
    top: 0px;
    bottom: 0px;
    z-index: 5;
  }

  &.open-cards {
    overflow-y: hidden;
  }
}

.messages-extra {
  bottom: 116px;
}

.messages {
  display: grid;
  grid-auto-rows: max-content;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  width: 100%;
  max-width: 100%;
  z-index: 5;

  @keyframes bot-message-loading {
    50% {
      opacity: 0.2;
    }
  }

  &::-webkit-scrollbar {
    transition: all 0.5s;
    width: 5px;
    height: 1px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #b3ada7;
  }
}

.message-carrossel {
  width: 99vw !important;
}

.message {
  height: 100%;
  width: 90%;
  padding-bottom: 4px;
  z-index: 5;

  .message-balloon {
    width: auto;
    height: auto;
    display: flex;
    max-width: 90%;
    min-height: 0;
    margin: 10px 10px 0px 10px;
    border-radius: $message__border;
    box-shadow: $message__box-shadow;
    border: none;
    white-space: pre-wrap;
    z-index: 5;
    flex-direction: column;

    .audio-container {
      white-space: normal;
    }
  }

  .audio {
    background-color: transparent !important;
  }
}

.message.center,
.message.left {
  display: flex;
  align-items: center;

  margin: 0;
  flex-direction: row;

  .message-balloon-bg {
    border-top-left-radius: 0;
    background-color: $messages-bot-ballon-background-color;
  }

  .message-balloon-time {
    background-color: $messages-time-ballon-background-color;
    color: $messages-time-ballon-color;
  }

  .message-balloon {
    float: left;
  }

  .message-info {
    .message-status {
      display: none;
    }
  }
}

.message-video {
  position: relative;
}

.message-info-video {
  position: absolute;
  right: 10px;
  bottom: 10px;
  .message-time {
    color: #fff !important;
  }
  .message-status {
    fill: #fff;
  }
}

.message-video-youtube {
  width: 100%;
}

@media (min-width: 600px) {
  .message-video-youtube {
    min-width: 456px;
  }
}

.message.left {
  .message-time,
  .message-text {
    color: $messages-bot-ballon-color;
  }
}

.message.center {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  .message-balloon-bg {
    border-top-left-radius: 8px;
    background-color: $messages-bot-ballon-background-color;
  }

  .message-text {
    color: $messages-bot-ballon-color !important;
  }

  .message-time {
    color: $messages-time-ballon-color !important;
  }
}

.message.right {
  margin: 0 10%;
  flex-direction: row-reverse;

  p {
    color: $background__text-color;
  }

  .message-time {
    color: $messages-user-ballon-color !important;
  }

  .message-balloon {
    float: right;
    border-bottom-right-radius: 0;
    background-color: $messages-user-ballon-background-color;
    color: $messages-user-ballon-color;
  }

  .audio {
    background-color: transparent !important;
  }

  .message-text {
    color: $messages-user-ballon-color !important;
  }
}

.message.right.audio {
  margin: 0;
  width: 100%;
}

.message.right + .message.right {
  .message-balloon {
    margin-top: 4px;
  }
}

.message.left + .message.left {
  .message-balloon {
    margin-top: 4px;
  }
}

.message-info {
  margin: 0 0 2px 0;
  padding: 0 7px 0 10px;
  color: $text-color;
  font-size: 0.7rem;
  align-self: flex-end;
  display: flex;

  .message-status {
    margin-left: 2px;
    svg {
      vertical-align: top;
    }
  }
}

.message-text {
  font-weight: $messages-font-weight;
  padding: 5px 10px;

  strong {
    font-weight: 800;
  }
}

.message-info {
  .message-status.sending {
    content: url("../../assets/static/clock.svg");
  }
  .message-status.sent {
    content: url("../../assets/static/singlecheck.svg");
  }
  .message-status.read {
    content: url("../../assets/static/doublecheck.svg");
  }
}

.copy-button {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 20px;
  min-height: 20px;

  border: 0px;
  border-radius: 50%;

  padding: 0;
  margin-top: 10px;

  background: $messages-bot-ballon-background-color;
  box-shadow: $message__box-shadow;

  cursor: pointer;
}

.copy-icon {
  fill: $messages-bot-ballon-color;
  width: 10px;
  height: 10px;
}
