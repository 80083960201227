@import "../../../styles/base/base.scss";

.list-modal-item-container {
  margin: 8px 8px 4px;

  .selected {
    border: 1px solid #000;
  }

  &__content {
    min-height: 70px;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 0.5fr 1fr 1fr 0.5fr;
    grid-template-areas:
      "left center center right"
      "left center center right";

    position: relative;
    padding: 8px;
    border-radius: 5px;
    background: $messages-bot-ballon-background-color;
    box-shadow: $message__box-shadow;
  }

  &__left {
    grid-area: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 14px;
    width: 100px;
    align-items: flex-start;

    & > img {
      border-radius: 4px;
      width: 100px;
      object-fit: contain;
    }

    & > p {
      font-size: 16px;
      color: $text-color;
      color: $messages-bot-ballon-color;
    }

    & > span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100px;
      font-size: 12px;
    }
  }

  &__center {
    grid-area: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 14px;
		width: 100%;
    color: $text-color;
    color: $messages-bot-ballon-color;
		height: max-content;

    & > strong {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-bottom: 8px;
      color: $text-color;
      color: $messages-bot-ballon-color;
    }

    & > span {
      display: block;
      display: -webkit-box;
      max-width: 100%;
      line-height: 1.2;
      // -webkit-line-clamp: 4;
      height: 70%;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
			// word-wrap: break-word;
      color: $text-color;
      color: $messages-bot-ballon-color;
			// overflow: auto;
    }
  }

  &__right {
    grid-area: right;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    min-width: 15%;
    height: 100%;
  }
}

.list-modal-item-select-confirm {
  position: absolute;
  top: 6px;
  right: 6px;

  & > svg {
    color: $text-color;
    color: $messages-bot-ballon-color;
  }
}

.list-modal-item-container.darkTheme {
  box-shadow: 0 0 3px 0px rgba(255, 255, 255, 0.726);

  .selected {
    border: 1px solid #fff;
  }

  .list-modal-item-select-confirm > svg {
    color: $text-color;
    color: $messages-bot-ballon-color;
  }
}
