@import "../../../styles/base/base.scss";

.list-modal-header-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $messages-bot-ballon-background-color;
  color: $text-color;
  padding: 0 16px;
  font-size: 24px;
  z-index: 5;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);

  .list-modal-header-name {
    font-size: 17px;
    font-weight: 550;
    text-overflow: ellipsis;
    letter-spacing: 0.3px;
    margin: 0 0 6px 16px;
    overflow: hidden;
    white-space: nowrap;
    width: 200px;
    color: $messages-bot-ballon-color;
  }

  .list-modal-header-left {
    display: flex;
    align-items: center;
    color: $messages-bot-ballon-color;
  }

  .list-modal-header-right {
    display: flex;
    align-items: center;
    color: $messages-bot-ballon-color;

    button {
      margin: 0 8px;
    }
  }

  .list-modal-header-back,
  .list-modal-header-confirm,
  .list-modal-header-cancel {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;

    svg {
      color: $messages-bot-ballon-color;
    }
  }

  // .list-modal-header-confirm {
  //   svg {
  //     color: #34ac26;
  //   }
  // }

  .list-modal-header-cancel {
    margin-right: 26px;
    // svg {
    //   color: #dc1414;
    // }
  }
}
