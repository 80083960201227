@import "../../../styles/base/base.scss";

.message-list {
  color: $text-color;
  display: flex;
  overflow-x: scroll;
  max-width: 95vw;
  padding-bottom: 5px;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    margin-right: 8px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $background-color;
    border-radius: 20px;
  }

  .selected-item {
    border: 3px solid $fintalk-green;
  }

  &__item {
    align-items: center;
    background: $message__background-color;
    border-radius: 0.5rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-right: 7px;
    position: relative;
    padding: 5px;
    width: 160px;
    box-shadow: $message__box-shadow;

    &__image {
      height: 100px;
      position: relative;
      width: 120px;

      img {
        height: 100%;
        width: 100%;
        max-height: 130px;
        object-fit: cover;
      }

      &__price {
        background: rgba(0, 0, 0, 0.541);
        border-radius: 3rem;
        color: $background__text-color;
        display: flex;
        position: absolute;
        padding: 2px 8px;
        font-weight: 700;
        bottom: 10px;
        right: 10px;
      }
    }

    &__info {
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      padding: 5px;
      width: 100%;

      &__title {
        align-items: center;
        justify-content: center;
        display: flex;
        overflow: hidden;
        line-height: 1.5em;
        font-size: 0.95em;
        text-overflow: ellipsis;
        height: 3em;
      }

      &__description {
        align-items: center;
        display: flex;
        font-size: 0.9em;
        justify-content: center;
        line-height: 1.115em;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 4px;
        max-width: 100%;
        height: 3rem;

        div {
          display: block;
          display: -webkit-box;
          width: 100%;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }

      &__price {
        font-weight: 900;
        font-size: 1.1em;
        width: 100%;
      }
    }
  }

  b {
    line-height: initial;
    font-size: initial;
    text-overflow: ellipsis;
    height: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
