@import "../../../styles/base/base.scss";

.container-location {
  display: flex;
  flex-direction: column;

  p {
    margin-top: 2px;
    padding-right: 0px;
  }
}

.send-location {
  font-size: 0.85rem;
  padding: 6px 6px 0 6px;
  margin-bottom: 6px;
  color: $background__text-color;
  word-break: break-word;
}

.marker {
  width: 0;
  height: 0;
}

.marker span {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  color: #fff;
  background: $fintalk-orange;
  border: solid 2px;
  border-radius: 0 70% 70%;
  box-shadow: 0 0 2px #000;
  transform-origin: 0 0;
  transform: rotateZ(-135deg);
}

.marker b {
  transform: rotateZ(135deg);
}

.temporary-marker span {
  background: #d3d3d3;
}
