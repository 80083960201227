@import "../../../styles/base/base.scss";

.selected-item {
  border: 2px solid $fintalk-green;
  border-radius: 4px;
  padding: 4px 0;
}

.centered {
  justify-content: center;
}

.hovered {
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background: #eeeeee;
  }
}

.no-image-no-description {
  padding: 10px 4px !important;
}

.list-container {
  background: $message__background-color;
  border-radius: $message__border;
  box-shadow: $message__box-shadow;
  color: $text-color;
  padding: 18px;
  max-width: 80vw;
  min-width: 100px;

  &__title {
    white-space: nowrap;
    align-items: left;
    display: flex;
    flex-direction: column;
    padding: 0 5px;

    h3 {
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1.3em;
      margin: 0;
      margin-bottom: 10px;
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  hr {
    border: 0.5px solid rgba(0, 0, 0, 0.13);
  }

  &__items {
    // padding: 0 5px 5px 5px;

    &__each-item {
      display: flex;
      // justify-content: space-evenly;
      align-items: center;
      height: auto;

      &__info {
        display: flex;
        flex-direction: column;
        width: auto;

        strong {
          text-overflow: ellipsis;
          // white-space: nowrap;
          overflow: hidden;
          height: 30%;

          padding: 4px 0;
        }

        span {
          display: block;
          display: -webkit-box;
          max-width: 100%;
          line-height: 1.2;
          // -webkit-line-clamp: 3;
          height: 70%;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 4px 0;
        }
      }

      &__image {
        margin-left: 10px;
        align-items: center;
        flex: 1;
        display: flex;
        justify-content: flex-end;

        img {
          border-radius: 4px;
          height: 80px;
          width: 100px;
          max-height: 100px;
          object-fit: cover;
        }
      }
    }
  }

  p {
    justify-content: flex-end;
    display: flex;
  }
}
