@import "../../styles/base/base.scss";


.list-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  background: $messages-bot-ballon-background-color;
  border-radius: $message__border;
  box-shadow: $message__box-shadow;
  color: $text-color;

  &__items {
    position: absolute;
    top: 55px;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 5;
  }

  &__suggestions {
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 5;

    & > button {
      border: none;
      background: none;
      width: 100%;
      height: 55px;
      font-size: 18px;
      color: $text-color;
      background: $background-color;
      box-shadow: $message__box-shadow;
      z-index: 5;
    }

    & > button:first-child {
      margin-top: 18px;
    }
  }
}
