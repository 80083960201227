@import "../../../styles/base/base.scss";

@keyframes up {
  from {
    transform: translateY(60vh);
  }
  to {
    transform: translateY(0);
  }
}

.list-modal-confirm-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 5;
  width: 100%;
  overflow: hidden;
}

.loading {
  justify-content: space-evenly !important;
}

.list-modal-confirm-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  height: 25vh;
  bottom: 0;
  right: 0;
  left: 0;
  background: $background-color;
  border-radius: 16px 16px 0 0;
  animation: up 0.5s;
  padding: 20px 20px 0;
  box-shadow: $message__box-shadow;

  &__close {
    position: absolute;
    top: 14px;
    left: 10px;
    background-color: transparent;
    border: none;

    & > svg {
      color: $text-color;
      stroke-width: 8px;
    }
  }

  &__title {
    width: 100%;
    font-size: 16px;
    color: #000;
    text-align: center;
    font-weight: 400;
  }

  &__text {
    width: 100%;
    font-size: 14px;
    color: #000;
    text-align: center;
    font-weight: 600;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 16px;
    gap: 16px;
  }

  &__footer-cancel,
  &__footer-confirm {
    border-radius: 8px;
    border: none;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
    padding: 25px;
    width: 100%;
    max-width: 150px;
    color: #000;

    transition: background-color 0.2s;
  }

  &__footer-confirm {
    color: #fff;
    background-color: #34ac26;

    &:hover {
      background-color: #2e9722;
    }
  }

  &__footer-cancel {
    color: #fff;
    background-color: #dc1414;

    &:hover {
      background-color: #a70e0e;
    }
  }
}

.list-modal-confirm-content.darkTheme {
  .list-modal-confirm-content__title,
  .list-modal-confirm-content__text,
  .list-modal-confirm-content__footer-confirm,
  .list-modal-confirm-content__footer-cancel {
    // color: $text-color;
  }
}
