@import "../../../styles/base/base.scss";

.message-doc {
  font-size: 0.85rem;
  padding: 5px;
  margin: 0;
  color: $background__text-color;
  word-break: break-word;
}

.doc-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.message-info-left {
  padding: 0 10px 0 7px;
  word-break: break-word;
}

.container-file {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: rgba(0, 0, 0, 0.2);
  width: auto;
  margin: 6px;
  padding: 4px;
  border-radius: 4px;

  span {
    display: flex;
    align-items: center;

    p {
      font-weight: bold;
      font-size: 0.9rem;
			color: $messages-bot-ballon-color;
    }
  }
}

.document-icons {
  color: $background__text-color;
  height: 2rem;
  width: 2rem;
}

.icon-download {
  height: 1.5rem;
  width: 1.5rem;
}

.document-download {
  padding: 6px;
  border-radius: 100%;
  border: 2px solid rgba(0, 0, 0, 0.2);
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
