@import "../../../styles/base/base.scss";

.list-modal-details-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background-color: $background-color;
}

.list-modal-details-header {
  position: sticky;
  top:0;
  left: 0;
  right: 0;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: $fintalk-orange;
  color: $background__text-color;
  padding: 0 16px;
  font-size: 24px;
  z-index: 5;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);

  & > button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    color: $background__text-color;
  }

  & > h1 {
    font-size: 16px;
    margin-left: 20px;
  }
}


.list-modal-details-body {
  background: red;
  max-width: 300px;
}
