@import "../../../styles/base/base.scss";

.message-text {
  font-size: 0.85rem;
  margin: 0;
  color: $background__text-color;
  word-break: break-word;

  a {
    color: $messages-link;
    font-weight: bold;
  }
  a:active {
    color: $messages-link;
  }
}

.message-outgoing {
  a {
    color: $background__text-color;
  }
  a:active {
    color: $background__text-color;
  }
}
