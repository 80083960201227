@import "../../styles/base/base.scss";

.two-items {
  grid-template-columns: 2fr 2fr !important;
}

.document-hover:hover {
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: darken(#dc143c, 15%) !important;
}
.gallery-hover:hover {
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: darken(#3a59be, 15%) !important;
}
.camera-hover:hover {
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: darken(#3abe62, 15%) !important;
}
.video-hover:hover {
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: darken(#e3478c, 15%) !important;
}
.location-hover:hover {
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: darken(#f35a46, 15%) !important;
}

.send-opt-menu {
  align-items: flex-end;
  animation: appear 200ms ease-in-out;
  background: rgba(0, 0, 0, 0.1);
  color: $text-color;
  transition: background 1s;
  bottom: 10vh;
  display: flex;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100vw;
  z-index: 5;

  @keyframes appear {
    from {
      opacity: 0;
    }
  }

  &__content {
    animation: zoom-out 150ms;
    background: $message__background-color;
    border-radius: $message__border;
    box-shadow: $message__box-shadow;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr;
    height: 40vh;
    padding: 1rem;
    width: 86vw;

    &__btn {
      align-items: center;
      display: flex;
      flex-direction: column;

      padding: 8px;
      border-radius: 8px;

      .optButtons {
        align-items: center;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        height: 4rem;
        width: 4rem;
        cursor: pointer;
        margin-bottom: 4px;

        svg {
          fill: $background__text-color;
          font-size: 2em;
        }
      }
    }

    @keyframes zoom-out {
      from {
        border-radius: 50%;
        transform: translateY(20px);
        height: 0;
        width: 0;
      }
    }
  }
}
