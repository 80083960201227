@import "./base/base.scss";

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  scrollbar-width: 6px;
  scrollbar-color: $background-color;
}

*:focus {
  outline: none !important;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  margin-right: 8px;
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: $background-color;
  border-radius: 20px;
}

body {
  background: $messages-background;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
