:root {
  --header-background-color: #483b8a;
  --header-color: #505050;

  --font: "Roboto", sans-serif;
	--messages-font-weight: 500;

  --footer-btn-send-background-color: #483b8a;
  --footer-btn-send-color: #fff;
  --footer-background-color: #fff;
  --footer-color: #fff;

  --messages-background: #fff;
  --messages-user-ballon-background-color: #f06d61;
  --messages-user-ballon-color: #fff;
  --messages-bot-ballon-background-color: #fff;
  --messages-bot-ballon-color: #f06d61;

  --messages-time-ballon-color: #fff;
  --messages-time-ballon-background-color: #f06d61;

  --messages-link: red;

  --background-color: #f8f8f8;
  --background__text-color: white;
  --text-color: #000000ad;
  --message__background-color: #f8f8f8;
  --fintalk-orange: #483b8a;
  --link-color: #8474d6;
}

$messages-font-weight: var(--messages-font-weight);
$font: var(--font);
$header-background-color: var(--header-background-color);
$header-color: var(--header-color);
$footer-btn-send-background-color: var(--footer-btn-send-background-color);
$footer-btn-send-color: var(--footer-btn-send-color);
$footer-background-color: var(--footer-background-color);
$footer-color: var(--footer-color);
$messages-background: var(--messages-background);
$messages-user-ballon-background-color: var(
  --messages-user-ballon-background-color
);
$messages-time-ballon-color: var(
  --messages-time-ballon-color
);

$messages-time-ballon-background-color: var(--messages-time-ballon-background-color);

$messages-bot-ballon-background-color: var(
  --messages-bot-ballon-background-color
);
$messages-user-ballon-color: var(--messages-user-ballon-color);
$messages-bot-ballon-color: var(--messages-bot-ballon-color);
$messages-link: var(--messages-link);

$fintalk-orange: var(--fintalk-orange);
$fintalk-green: #40a52c;
$background-color: var(--background-color);
$background__text-color: var(--background__text-color);
$text-color: var(--text-color);
$message__background-color: var(--message__background-color);
$link-color: var(--link-color);
