@import "../../styles/base/base.scss";

.dog-image {
  background: url("../../assets/images/sad-dog.png");
  width: 150px;
  max-height: 200px;
  height: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  text-align: center;
}

.notauthorized-container {
  background-color: transparent;
  height: 100vh;
  padding: 32px;
  padding-top: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.notauthorized-title {
  color: $fintalk-orange;
  text-align: center;
  margin: 32px 0 8px;
}

.notauthorized-content {
  color: $text-color;
  text-align: center;
  margin: 0px;
}

.notauthorized-button {
  background-color: $fintalk-orange;
  margin-top: 24px;
  border-style: none;
  //color: $text-color;
  color: #fff;
  font-weight: bold;
  padding: 12px 32px;
  border-radius: 24px;
}
