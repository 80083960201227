.main-div {
  display: flex;
  align-items: center;
  justify-content: center;

  background: #ececec;

  height: 100vh;
  padding: 0.5rem;
  line-height: 1.1rem;

  text-align: center;
}
