@import "../../../styles/base/base.scss";

.camera-screen {
  align-items: center;
  backdrop-filter: blur(2px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  width: 100vw;
  z-index: 999;

  &__canvas {
    height: calc(100% - 61px);
    width: 100%;
  }

  &__nav {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: 80px;

    button {
      background-color: transparent;
      color: $background__text-color;
      font-size: 2.8em;
      border: none;

      display: flex;
      align-items: center;

      :hover {
        background-color: #c7c7c7;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }

      svg {
        fill: $text-color;
        border-radius: 50%;
        padding: 12px;
      }
    }
  }
}
