@import "../../../styles/base/base.scss";

.container {
  top: 0px;
  left: 0;
  position: fixed;
  background: #fff;
  z-index: 4;
  height: 100%;
  width: 100%;
}

.iframe {
  width: 100%;
  height: 100%;
}

.btn-back {
  background-color: #009400;
  color: #fff;
  border: none;
  outline: none;
  margin: 5px;
  padding: 5px;
  border-radius: 6px;
}
