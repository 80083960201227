@import "../../styles/base/base.scss";

.a2hs-button {
  align-items: center;
  border-radius: 8px;
  border: 0;

  height: 38px;
  width: 88px;

  text-align: center;
  z-index: 999;
  font-size: 0.65rem;

  background: $messages-user-ballon-background-color;
  color: $messages-user-ballon-color;
}

.a2hs-button-without-dark-theme {
  right: 8px;
}

.header-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;

  overflow: hidden;
  background: $header-background-color;
  color: $header-color;
  padding: 0 8px;
  font-size: 24px;
  position: sticky;
  top: 0;
  z-index: 5000;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);

  position: absolute;
  left: 0px;
  right: 0px;

	.down {
		transition-property: all;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		transition-duration: 500ms;
		z-index: 5000;
	}

  .header-photo {
    margin: 0 0 0 5px;
    width: 36px;

    img {
      border-radius: 50%;
      display: block;
      width: 100%;
    }
  }

  .header-name {
    font-size: 17px;
    font-weight: 550;
    text-overflow: ellipsis;
    letter-spacing: 0.3px;
    margin: 0 0 0 8px;
    overflow: hidden;
    white-space: nowrap;
    width: auto;
    opacity: 0.8;
  }

  .change-theme-btn-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;

    button {
      background: none;
      border: none;

      svg {
        fill: $header-color;
        vertical-align: top;
        font-size: 2em;
      }
    }
  }
}
