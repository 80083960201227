@import "../../styles/base/base.scss";

.loading-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.loader {
  border: 4px solid transparent;
  border-radius: 50%;
  border-top: 4px solid $link-color;
  width: 42px;
  height: 42px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
